import './App.css';
import React, { Suspense } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('./pages'));
const Education = React.lazy(() => import('./pages/education'));
const BankAccount = React.lazy(() => import('./pages/bankAccount'));
const About = React.lazy(() => import('./pages/about'));
const Faq = React.lazy(() => import('./pages/faq'));
const Contact = React.lazy(() => import('./pages/contact'));
const Steps = React.lazy(() => import('./pages/steps'));
const Login = React.lazy(() => import('./pages/login'));
const Logout = React.lazy(() => import('./pages/logout'));
const Signup = React.lazy(() => import('./pages/signup'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));

function App() {
  return (
    <Router>
      <Suspense >
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/education" element={<Education />} exact />
          <Route path="/bank-account" element={<BankAccount />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/faq" element={<Faq />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/steps" element={<Steps />} exact />
          <Route path="/steps/:id" element={<Steps />} exact />
          <Route path="/login" element={<Login />} exact />
          <Route path="/signup" element={<Signup />} exact />
          <Route path="/logout" element={<Logout />} exact />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
